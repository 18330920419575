import cn from 'classnames';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useDebounce } from 'src/hooks/use-debounce';
import { seacrhByGameNameRequest } from 'src/services/gameApi';
import { setGamesTotalCount, setSelectedTag } from 'src/store/games/actions';
import { toggleMobileSearch } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import { SLIDER_DEFAULT_MAX_COUNT } from 'src/utils/constants';
import { Logo } from '../../Logo';
import GamesSlider from '../../Slider/GamesSlider';
import _styles from './styles.module.scss';
import ViewWithoutResult from './ViewWithoutResult';
import ViewWithResult from './ViewWithResult';

const MobileSearch: FC = () => {
  const { t }: Translation = useTranslation();

  const searchModalRef: any = useRef();

  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setGamesTotalCount = useConnectedAction(setGamesTotalCount);
  const _toggleMobileSearch = useConnectedAction(toggleMobileSearch);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { categoriesData } = useSelector((state: RootState) => state.configs);
  const { selectedTag, selectedProvider } = useSelector((state: RootState) => state.games);

  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [suggestedGames, setSuggestedGames] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);

  const debouncedSearchTerm: any = useDebounce(search, 500);

  const getSearchedGame = (): void => {
    seacrhByGameNameRequest('mobile', debouncedSearchTerm, selectedProvider, [selectedTag], selectedCategory).then(
      (res: any) => {
        _setGamesTotalCount(res.totalCount);
        setTags(res.games);
      }
    );
  };

  const onChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    setSearch(ev.target.value);
    if (ev.target.value?.trim().length >= 3) {
      setIsSubmited(true);
      // getSearchedGame(ev);
    } else {
      setIsSubmited(false);
      setTags([]);
    }

    selectedTag === 'home' && _setSelectedTag('allGames');
  };

  const onSearchInputValueRemove = (): void => {
    setSearch('');
    setIsSubmited(false);
  };

  const closeCategoriesMenu = (): void => {
    setBodyOverflow('set');
    _toggleMobileSearch();
  };

  useEffect(() => {
    if (categoriesData) {
      const suggestedTags =
        categoriesData[selectedCategory]?.tags?.filter((f) => f.tagName === 'allGames')?.[0].tagGames || [];

      if (!!suggestedTags.length) {
        setSuggestedGames(
          categoriesData[selectedCategory]?.tags
            ?.filter((f) => f.tagName === 'allGames')[0]
            .tagGames.slice(0, SLIDER_DEFAULT_MAX_COUNT)
        );
      }
    }
  }, [categoriesData, selectedCategory]);

  // useEffect(() => {
  //   if (debouncedSearchTerm.length <= 3) {
  //     const tags = categoriesData[selectedCategory]?.tags?.filter((f) => f.tagName === 'allGames')?.[0].tagGames || [];
  //     setTags(tags);
  //     _setGamesTotalCount(tags.length);
  //   } else {
  //     getSearchedGame();
  //   }
  // }, [debouncedSearchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm.length > 3) {
      getSearchedGame();
    }
  }, [debouncedSearchTerm]);

  const content = useMemo(
    () => (tags?.length ? <ViewWithResult t={t} games={tags} /> : <ViewWithoutResult t={t} view={'noResult'} />),
    [tags, debouncedSearchTerm]
  );

  useEffect(() => {
    document.body.setAttribute('style', 'overflow: hidden');
    return () => {
      document.body.removeAttribute('style');
    };
  }, []);

  return (
    <div className={_styles.search_container} ref={searchModalRef}>
      <div>
        <div className={_styles.logo}>
          <Logo settings={{ src: 'logo.png' }} />
        </div>
        <div className={_styles.menu_icon_wrapper}>
          <p className={_styles.title}>{t('search')}</p>
          <Button type="button" className={_styles.close_menu_wrapper} onClick={closeCategoriesMenu}>
            <SvgIcon src={icons.close} />
          </Button>
        </div>
      </div>
      <div className={_styles.content_wrapper}>
        <div>
          <div className={cn(_styles.search_input, 'mobile_search_input')}>
            <Input onChange={onChange} value={search} name={''} placeholder={t('search')} />
            <SvgIcon src={icons.close} className={_styles.input_close_icon} onClick={onSearchInputValueRemove} />
            <div className={cn(_styles.search_icon, 'mobile_search_icon')}>
              <SvgIcon src="/images/icons/svg/search.svg" />
            </div>
          </div>
          {isSubmited && content}
        </div>

        {!isSubmited && <ViewWithoutResult t={t} view={'default'} />}

        <GamesSlider
          data={suggestedGames}
          tagName={t('suggestedForYou')}
          template="template_2"
          showViewAllLink={false}
        />
      </div>
    </div>
  );
};
export default MobileSearch;
