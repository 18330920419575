import cn from 'classnames';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { detectDevice, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useDebounce } from 'src/hooks/use-debounce';
import { gameListRequest } from 'src/services/gameApi';
import { setGamesTotalCount, setSelectedTag, setTags } from 'src/store/games/actions';
import { toggleMobileProvider } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import { Logo } from '../../Logo';
import MultiSelectProvidersList from './components/MultiSelectProvidersList';
import _styles from './styles.module.scss';
import ViewWithoutResult from './ViewWithoutResult';

const MobileProviders: FC = () => {
  const { t }: Translation = useTranslation();
  const device = detectDevice();
  const providerModalRef: any = useRef();

  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _toggleMobileProvider = useConnectedAction(toggleMobileProvider);
  const _setTags = useConnectedAction(setTags);
  const _setGamesTotalCount = useConnectedAction(setGamesTotalCount);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { selectedProvider, selectedTag } = useSelector((state: RootState) => state.games);
  const { generalConfigs, categoriesData } = useSelector((state: RootState) => state.configs);

  const tags = categoriesData[selectedCategory]?.tags?.filter((f) => f.tagName === 'allGames')[0]?.tagGames || [];
  const gamesCount =
    device === 'mobile' ? generalConfigs?.gamesCountPerPageMobile : generalConfigs?.gamesCountPerPageDesktop;

  const [searchVal, setSearchVal] = useState<string>('');
  const [providers, setProviders] = useState<string[]>([]);
  const [selectedProvidersList, setSelectedProvidersList] = useState<string[]>(selectedProvider);

  const debouncedSearchTerm: any = useDebounce(searchVal, 500);

  const getGameList = (provider: string[], offset: number, tag: string[] | null): void => {
    gameListRequest(device, provider, offset, selectedCategory, tag, gamesCount).then((res: any) => {
      _setGamesTotalCount(res.totalCount);
      _setTags(res.games);
    });
  };

  const toggleAllProviders = (): void => {
    setSelectedProvidersList(selectedProvidersList.includes('all_providers') ? [] : ['all_providers']);
  };

  const removeProvider = (provider: string): void => {
    const index = selectedProvidersList.indexOf(provider);
    if (index !== -1) {
      selectedProvidersList.splice(index, 1);
    }
  };

  const addProvider = (provider: string): void => {
    setSelectedProvidersList([...selectedProvidersList, provider]);
  };

  const selectMultiProviders = (provider: string) => () => {
    localStorage.getItem('selectedTag') === 'home' && _setSelectedTag('allGames');
    if (provider === 'all_providers') {
      toggleAllProviders();
    } else {
      if (selectedProvidersList.includes('all_providers')) {
        removeProvider('all_providers');
      }
      if (selectedProvidersList.includes(provider)) {
        setSelectedProvidersList(selectedProvidersList.filter((item) => item !== provider));
      } else {
        addProvider(provider);
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    setSearchVal(value);

    const foundedProviders = categoriesData[selectedCategory]?.providers?.filter((f) =>
      f.toLocaleLowerCase()?.trim().includes(value.toLocaleLowerCase()?.trim())
    );

    setProviders(foundedProviders);
  };

  const onSearchInputValueRemove = (): void => {
    setSearchVal('');
    setProviders(categoriesData[selectedCategory]?.providers);
  };

  const closeProvidersFIlter = (): void => {
    setBodyOverflow('set');
    _toggleMobileProvider();
  };

  const content = useMemo(() => {
    if (providers?.length) {
      return (
        <MultiSelectProvidersList
          providers={providers}
          selectProvider={selectMultiProviders}
          selectedProvidersList={selectedProvidersList}
        />
      );
    }

    return <ViewWithoutResult t={t} />;
  }, [providers, debouncedSearchTerm, selectedProvidersList]);

  useEffect(() => {
    if (categoriesData && selectedCategory) {
      const categoryData = categoriesData[selectedCategory];

      setProviders(categoryData?.providers);
    } else {
      setProviders(['all_providers']);
    }
  }, [categoriesData, selectedCategory]);

  useEffect(() => {
    document.body.setAttribute('style', 'overflow: hidden');
    return () => document.body.removeAttribute('style');
  }, []);

  // TO DO must review the part and if it not neccasary remmove the call

  useEffect(() => {
    const tag = ['allGames', 'home'].includes(selectedTag) ? null : [selectedTag];
    const offset = Math.floor(tags.length / 24) <= 1 ? 0 : Math.floor(tags.length / 24);
    getGameList([], offset, tag);
  }, [selectedTag, tags, debouncedSearchTerm]);

  return (
    <div className={_styles.provider_container} ref={providerModalRef}>
      <div>
        <div className={_styles.logo}>
          <Logo settings={{ src: 'logo.png' }} />
        </div>
        <div className={_styles.menu_icon_wrapper}>
          <p className={_styles.title}>{t('providers')}</p>
          <Button type="button" className={_styles.close_menu_wrapper} onClick={closeProvidersFIlter}>
            <SvgIcon src={icons.close} />
          </Button>
        </div>
      </div>
      <div className={_styles.content_wrapper}>
        <div className={cn(_styles.search_input, 'mobile_search_input')}>
          <Input onChange={onChange} value={searchVal} name={''} placeholder={t('search')} />
          <SvgIcon src={icons.close} className={_styles.input_close_icon} onClick={onSearchInputValueRemove} />
          <div className={cn(_styles.search_icon, 'mobile_search_icon')}>
            <SvgIcon src="/images/icons/svg/search.svg" />
          </div>
        </div>
        {content}
      </div>
    </div>
  );
};
export default MobileProviders;
