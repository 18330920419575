import { FC } from 'react';
import { SvgIcon } from 'src/components/common/SvgIcon';
import _styles from './styles.module.scss';

type Props = {
  t: (arg: string) => string;
  view: 'default' | 'noResult';
};
const ViewWithoutResult: FC<Props> = ({ t, view }) => (
  <div className={_styles.no_result_body}>
    <div className={_styles.result_search_icon}>
      <SvgIcon src="/images/icons/svg/content_search.svg" />
    </div>
    <p className={_styles.title}>{t(view === 'default' ? 'searchDefaultTitle' : 'noResultFoundTitle')}</p>
    <p className={_styles.subTitle}>{t(view === 'default' ? 'searchDefaultText' : 'noResultFoundText')}</p>
  </div>
);
export default ViewWithoutResult;
