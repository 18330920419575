import { FC, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { getFromQueryParams, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { toggleMobileProvider } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import { SPECIAL_TAGS } from 'src/utils/constants';
import ProviderDropdownView from './components/ProviderDropdownView/ProviderDropdownView';
import ProviderListView from './components/ProviderListView';
import ProviderScrollableView from './components/ProviderScrollableView';
import './styles.scss';

type ProviderFilterProps = {
  settings: {
    type: string; //'byProvider';
    view: 'scrollableList' | 'accordion' | 'dropdown';
    priority: number;
    styles: { [key: string]: any };
  };
};
const ProviderFilter: FC<ProviderFilterProps> = ({ settings }) => {
  const { view } = settings;
  const location = useLocation();

  const { t }: any = useTranslation();
  const { locale } = useI18n();

  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);
  const _toggleMobileProvider = useConnectedAction(toggleMobileProvider);

  const { selectedCategory, sectionLoading } = useSelector((state: RootState) => state.app);
  const { categoriesData } = useSelector((state: RootState) => state.configs);
  const { selectedProvider, selectedTag } = useSelector((state: RootState) => state.games);
  const [openProvidersList, setOpenProvidersList] = useState<boolean>(false);
  const [providers, setProviders] = useState<string[]>([]);
  const [collectedData, setCollectedData] = useState<string[]>([]);
  const getSelectedProvider = localStorage.getItem('selectedProvider');
  const togglePovidersFilterBtn = (): void => {
    setOpenProvidersList((prevState) => !prevState);
  };

  const onProviderChange = (provider: string[]): void => {
    localStorage.getItem('selectedTag') === 'home' && _setSelectedTag('allGames');
    const providerItem = Array.isArray(provider) ? provider[0] : provider;
    if (view === 'dropdown') {
      if (provider.includes('all_providers')) {
        _setSelectedProvider(['all_providers']);
      } else {
        _setSelectedProvider(provider);
      }
    } else {
      setCollectedData((prevData) => {
        if (prevData.includes(providerItem)) {
          return prevData.filter((item) => item !== providerItem);
        } else {
          return [...prevData, providerItem];
        }
      });
    }
  };
  const openProviderFilterModal = (): void => {
    if (!SPECIAL_TAGS.includes(selectedTag)) {
      setBodyOverflow('unset');
      _toggleMobileProvider();
    }
  };

  useEffect(() => {
    if (collectedData.length) {
      _setSelectedProvider(collectedData);
      if (collectedData.includes('all_providers')) {
        setCollectedData([]);
        _setSelectedProvider(['all_providers']);
      }
    } else {
      _setSelectedProvider(['all_providers']);
    }
  }, [collectedData, providers]);

  useEffect(() => {
    if (selectedTag === 'home') {
      localStorage.removeItem('selectedProvider');
      setCollectedData([]);
    }
  }, [selectedTag]);

  useEffect(() => {
    setCollectedData([]);
  }, [location]);

  const { search } = useLocation();

  const params = getFromQueryParams(search);

  useEffect(() => {
    const providerArray = getSelectedProvider?.split(',');
    if (
      params.providername &&
      providers.findIndex(
        (provider: any) => provider.replace(/\s+/g, '').toLowerCase() === params.providername.toLowerCase()
      ) > -1
    ) {
      _setSelectedTag('allGames');
      setCollectedData([params.providername]);
      _setSelectedProvider([params.providername]);
    } else if (providerArray?.length) {
      setCollectedData(providerArray);
      _setSelectedProvider(providerArray);
    }
  }, [selectedCategory, providers]);

  const providerView: any = useMemo(
    () => ({
      scrollableList: (
        <ProviderScrollableView
          providers={providers}
          selectedProvider={selectedProvider}
          onProviderChange={onProviderChange}
          togglePovidersFilterBtn={togglePovidersFilterBtn}
          openProvidersList={openProvidersList}
          selectedCategory={selectedCategory}
          setOpenProvidersList={setOpenProvidersList}
        />
      ),
      list: (
        <ProviderListView
          providers={providers}
          selectedProvider={selectedProvider}
          onProviderChange={onProviderChange}
          togglePovidersFilterBtn={togglePovidersFilterBtn}
          openProvidersList={openProvidersList}
        />
      ),
      dropdown: sectionLoading.includes(false) && (
        <ProviderDropdownView providers={providers} onProviderChange={onProviderChange} />
      ),
    }),
    [providers, selectedProvider, selectedCategory, openProvidersList, sectionLoading]
  );

  const mobileButtonView = useMemo(
    () =>
      isMobileOnly ? (
        <>
          {selectedProvider.length >= 1 && selectedProvider[0] !== 'all_providers' ? (
            <div className="count_badge">
              <Typography className="provider_title" variant={'h5'}>
                {t('selected')} {selectedProvider.length}
              </Typography>
            </div>
          ) : (
            <Typography className="provider_title" variant={'h5'}>
              {t('providers')}
            </Typography>
          )}
        </>
      ) : (
        <></>
      ),
    [selectedProvider, locale]
  );

  useEffect(() => {
    if (categoriesData[selectedCategory]?.providers) {
      setProviders([
        'all_providers',
        ...categoriesData[selectedCategory]?.providers.sort((a: any, b: any) =>
          a[0].toLowerCase().localeCompare(b[0].toLowerCase())
        ),
      ]);
    } else {
      setProviders(['all_providers']);
    }
  }, [categoriesData, selectedCategory]);

  useEffect(() => {
    setOpenProvidersList(false);
  }, [selectedCategory]);

  return (
    <>
      {isMobileOnly ? (
        <div className="providers_filter_mobile_container">
          <Button
            fontWeight="bold"
            className="secondary-outlined-btn provider_collapse"
            justifyContentValue="space-between"
            onClick={openProviderFilterModal}
          >
            {mobileButtonView}
            <SvgIcon src="/images/icons/svg/filled_arrow_down.svg" className="arrow" />
          </Button>
        </div>
      ) : (
        <>{providerView[view]}</>
      )}
    </>
  );
};
export default ProviderFilter;
