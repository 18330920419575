import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { RootState } from 'src/types/store-types';
import { MediaManager } from '../../../manager/MediaManager';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    imageSrc: string;
    type: string;
    link: string;
    styles: Styles;
  };
};
const Licence: FC<Props> = ({ settings }) => {
  const { styles, type, imageSrc, link } = settings;

  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const licenceComonent = useMemo(() => {
    if (type === 'iframe') {
      return <iframe title="licence" src={generalConfigs?.gambleLicence ?? ''} style={styles}></iframe>;
    }

    if (type === 'image') {
      return <SvgIcon src={'/images/footer/licence.svg'} className="image_licence" />;
    }

    if (type === 'curacaoIcon') {
      return (
        <a style={styles} href={link} className="curacao_icon">
          <img src={MediaManager.getSrcFromMediaAssets(imageSrc, '/')} alt="Curacao Icon" />
        </a>
      );
    }

    return (
      <a style={styles} href={generalConfigs?.gambleLicence ?? ''} target="_blank">
        <img src={imageSrc || '/images/license.png'} alt="Licence" />
      </a>
    );
  }, []);

  return <div className={'licence_wrapper'}>{licenceComonent}</div>;
};
export default Licence;
